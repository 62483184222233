import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Info from '@material-ui/icons/Info';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { InsightsCard } from './InsightsCard';
import { BrancherCard } from '../../Components/General/BrancherCard';
import { EInsights } from '../../store/reducers/UserInfoReducer';
import { Text } from '../../Components/General/Text';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { PersonalityScaler } from './PersonalityScaler';
import { BrancherRadarChart } from '../../Components/General/BrancherRadarChart';
import { IStoreTypes } from '../../store/storeTypes';
import {
  BrancherDispatch,
  UtilGetUserInsights,
  UtilRequestUnlockInsights,
} from '../../store/actions';
import { EMatchInsightType, SimpleMatchInsights } from './SimpleMatchInsights';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { AcceptButton, RejectButton } from '../../Components/InputFields/BrancherButton';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { PersonalityMeanings, sortObjectsByEnum, ValuesMeanings } from './MatchInsights';
import { PageLayout } from '../../Components/General/PageLayout';
import { GetRoute } from '../../Components/Routing';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { InsightsFeedback } from './InsightsFeedback';

const useStyles = makeStyles({
  rootInsights: {
    marginTop: 20,
    marginBottom: 50,
  },
  personalInsights: {
    marginTop: 40,
  },
});

interface IInsights {
  simple?: boolean;
}

export const Insights: React.FC<IInsights> = (props) => {
  const insights = useSelector((state: IStoreTypes) => state.user?.myInsights);
  const paid = useSelector((state: IStoreTypes) => state.user?.paidInsights);
  const sessionRoleId = useSelector((state: IStoreTypes) => state.user?.sessionRoleId);
  const sesPos = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const isMentee = sesPos === ProgramPositions.mentee;
  const programId = useSelector((state: IStoreTypes) => state.user?.programId);
  const insightsUnlockRequest = useSelector(
    (state: IStoreTypes) => state.user?.insightsUnlockRequest,
  );
  const [tileLegend, setTileLegend] = React.useState<'values' | 'personality'>();
  const [openTileDescription, setOpenTileDescription] = React.useState<boolean>(false);
  const [openRequestUnlock, setOpenRequestUnlock] = React.useState<boolean>(false);
  const [requestingUnlock, setRequestingUnlock] = React.useState<boolean>(false);
  const [gettingInsights, setGettingInsights] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const styles = useStyles();

  const setSendOpenRequestUnlock = () => {
    setRequestingUnlock(true);
    BrancherDispatch(
      dispatch,
      UtilRequestUnlockInsights(() => {
        setOpenRequestUnlock(false);
        setRequestingUnlock(false);
      }),
    );
  };

  React.useEffect(() => {
    setGettingInsights(true);
    BrancherDispatch(
      dispatch,
      UtilGetUserInsights(() => {
        setGettingInsights(false);
      }),
    );
  }, []);

  const InsightsSimple = () => (
    <Grid container className={styles.rootInsights} justify="center">
      {props.simple && (
        <Grid item>
          <Text variant="md" color="purple" fontWeight={600} marginTop={20} marginBottom={20}>
            Your Personality & Values Insights
          </Text>
        </Grid>
      )}
      <BrancherDialog
        setClose={() => setOpenRequestUnlock(false)}
        open={openRequestUnlock}
        labelledBy="unlock personal insights"
        contentWidth
        title="Would you like to 'Request to Unlock' this insight?"
      >
        <Grid container justify="center">
          <Grid container item justify="center">
            <Text variant="md" marginBottom={50} marginTop={20}>
              This will notify your program administrator that you would like to see this insight.
            </Text>
          </Grid>
          <Grid item xs={12} md={8} container justify="space-between">
            <RejectButton disabled={requestingUnlock} onClick={() => setOpenRequestUnlock(false)}>
              No
            </RejectButton>
            <AcceptButton disabled={requestingUnlock} onClick={setSendOpenRequestUnlock}>
              Yes
            </AcceptButton>
          </Grid>
        </Grid>
      </BrancherDialog>
      <BrancherDialog
        labelledBy={tileLegend === 'values' ? 'values-legend' : 'personality-legend'}
        open={openTileDescription}
        setClose={() => setOpenTileDescription(false)}
        contentWidth
        title={tileLegend === 'values' ? 'Values Legend' : 'Personality Legend'}
      >
        {(tileLegend === 'values' ? ValuesMeanings : PersonalityMeanings).map((val) => (
          <Box display="block" width="100%">
            <Text variant="md" fontWeight={600} color="purple" display="inline">
              {val.readableName}:{' '}
            </Text>
            <Text variant="md" fontWeight={500} display="inline">
              {val.description}
            </Text>
          </Box>
        ))}
      </BrancherDialog>
      <Grid container item xs={props.simple ? 10 : 12} justify="center">
        <Grid container item xs={12} spacing={2}>
          <Grid item>
            <Text
              variant="md"
              color="purple"
              fontWeight={600}
              marginTop={props.simple ? 0 : -30}
              marginBottom={15}
            >
              Discover Your Unique Strengths and Growth Areas
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text variant="sm" display="inline">
              Your{' '}
            </Text>
            <Text variant="sm" display="inline" fontWeight={600}>
              Self-Insights{' '}
            </Text>
            <Text variant="sm" display="inline">
              help you understand your personality, values, strengths, and potential blind spots.
              These insights can guide your personal development, shape your mentoring journey, and
              help you reflect on how you work best with others. Use this information to set
              meaningful goals and make the most of your mentoring experience.
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text variant="sm">
              If something doesn’t feel quite right, it might be worth exploring—often, when people
              disagree with their results, it can highlight a potential blind spot or an area for
              reflection. Consider how others might see you or how these traits show up in different
              situations. Use this as a learning opportunity to deepen your self-awareness.
            </Text>
          </Grid>
          {!props.simple && (
            <Grid item xs={12}>
              <Text variant="sm" display="inline">
                You can update your Personality and Values Insights anytime by{' '}
              </Text>
              <BrancherLinkText
                fontWeight={500}
                underline="always"
                variant="sm"
                display="inline"
                href={GetRoute('qualifying').path}
              >
                clicking the pencil icon above
              </BrancherLinkText>
              <Text variant="sm" display="inline">
                {' '}
                and re-doing the assessment. This will reset your personal results, but your Match
                Insights results will remain the same, as they are locked at the time of matching.
              </Text>
            </Grid>
          )}
        </Grid>
        {!gettingInsights && !!insights?.personality ? (
          <Grid container justify="space-between" spacing={2} className={styles.personalInsights}>
            <Grid item container xs={12} lg={6}>
              <BrancherCard>
                <Grid item xs={12} container justify="space-between">
                  <Grid item>
                    <Text variant="lg" fontWeight={700} marginBottom={4}>
                      My Personality
                    </Text>
                    <Text variant="xxs" fontWeight={600} color="placeholderGrey">
                      My unique approach
                    </Text>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setTileLegend('personality');
                        setOpenTileDescription(true);
                      }}
                    >
                      <Info fontSize="large" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <PersonalityScaler
                    personalityScales={sortObjectsByEnum(insights.personality, 'personality')}
                    isMatch={false}
                  />
                </Grid>
              </BrancherCard>
            </Grid>
            <Grid item container xs={12} lg={6} spacing={2}>
              <Grid container item xs={12}>
                <InsightsCard
                  {...insights?.insights?.[EInsights.STRENGTHS]}
                  insightType={EInsights.STRENGTHS}
                />
              </Grid>
              <Grid container item xs={12}>
                <InsightsCard
                  {...insights?.insights?.[EInsights.WATCHOUTS]}
                  insightType={EInsights.WATCHOUTS}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <BrancherDivider marginTop={50} marginBottom={50} />
            </Grid>
            <Grid container justify="center">
              <Grid container justify="space-between" spacing={2}>
                <Grid item container xs={12} lg={6}>
                  <BrancherCard>
                    <Grid item xs={12} container justify="space-between">
                      <Grid item>
                        <Text variant="lg" fontWeight={700} marginBottom={4}>
                          My Values
                        </Text>
                        <Text variant="xxs" fontWeight={600} color="placeholderGrey">
                          What matters most to you
                        </Text>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            setTileLegend('values');
                            setOpenTileDescription(true);
                          }}
                        >
                          <Info fontSize="large" />
                        </IconButton>
                      </Grid>
                    </Grid>
                    {paid ? (
                      <Grid item xs={12} container alignItems="flex-start">
                        <BrancherRadarChart
                          categories={sortObjectsByEnum(insights.values, 'values').map(
                            (b) => b.attributeName,
                          )}
                          yourCategoryValues={sortObjectsByEnum(insights.values, 'values').map(
                            (b) => b.value,
                          )}
                        />
                      </Grid>
                    ) : (
                      <>
                        <SimpleMatchInsights
                          insights={insights.values}
                          insightType={EMatchInsightType.VALUES}
                        />
                        <Grid item xs={12}>
                          <Grid item container justify="space-between" xs={12}>
                            {!insightsUnlockRequest?.[programId]?.[sessionRoleId] ? (
                              <Grid item alignItems="center" container>
                                <Text variant="xxs" display="inline">
                                  Want to know more?{' '}
                                </Text>
                                <BrancherLinkText
                                  onClick={() => setOpenRequestUnlock(true)}
                                  underline="always"
                                  display="inline"
                                  variant="xxs"
                                  fontWeight={600}
                                  color="purple"
                                >
                                  Request to Unlock
                                </BrancherLinkText>
                              </Grid>
                            ) : (
                              <Grid item>
                                <Text variant="xxs" fontWeight={600} color="purple">
                                  Insights requested
                                </Text>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </BrancherCard>
                </Grid>
                <Grid container item xs={12} lg={6} spacing={2}>
                  <Grid container item xs={12}>
                    <InsightsCard
                      {...insights?.insights?.[EInsights.MOTIVATORS]}
                      insightType={EInsights.MOTIVATORS}
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    <InsightsCard
                      {...insights?.insights?.[EInsights.DRAINERS]}
                      insightType={EInsights.DRAINERS}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <CircularProgress color="secondary" size={64} />
        )}
      </Grid>
    </Grid>
  );

  const MenteeGoalReflectionQuestions: string[] = [
    'What do you want to gain from this mentoring experience?',
    'What skills, knowledge, or perspectives would you like to develop?',
    'What can you start doing to make the most of this opportunity?',
    'What can you stop doing that may hold you back?',
    'How can your mentor support you in this journey?',
  ];

  const MentorGoalReflectionQuestions: string[] = [
    'What do you want to gain from this mentoring experience?',
    'What skills, knowledge, or perspectives would you like to refine as a mentor?',
    'What can you start doing to create a valuable experience for your mentee?',
    'What can you stop doing that may limit your effectiveness as a mentor?',
    'How can you best support your mentee’s growth and development?',
  ];

  const GoalReflectionQuestions = isMentee
    ? MenteeGoalReflectionQuestions
    : MentorGoalReflectionQuestions;

  return props.simple ? (
    <InsightsSimple />
  ) : (
    <PageLayout pageTitle="Your Personality & Values Insights">
      <Grid container item xs={12} spacing={4}>
        <InsightsSimple />
        <Grid container item xs={12}>
          <InsightsFeedback />
        </Grid>
        <Grid item container xs={12}>
          <BrancherCard padding="40px">
            <Grid item xs={12}>
              <Text variant="xl" fontWeight={700}>
                Your Mentoring Goals
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Text variant="sm" color="secondaryGrey" fontWeight={600} marginBottom={20}>
                Reflect on your insights to shape your mentoring experience.
              </Text>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              {GoalReflectionQuestions.map((question) => (
                <Grid item xs={12}>
                  <Text variant="sm" fontWeight={600} color="purple">
                    {question}
                  </Text>
                </Grid>
              ))}
            </Grid>
          </BrancherCard>
        </Grid>
      </Grid>
    </PageLayout>
  );
};
