import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Info from '@material-ui/icons/Info';
import { useDispatch, useSelector } from 'react-redux';
import { InsightsCard } from './InsightsCard';
import { EInsights } from '../../store/reducers/UserInfoReducer';
import { Text } from '../../Components/General/Text';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { PageLayout } from '../../Components/General/PageLayout';
import { PersonalityScaler } from './PersonalityScaler';
import { BrancherRadarChart } from '../../Components/General/BrancherRadarChart';
import { EMatchInsightType, SimpleMatchInsights } from './SimpleMatchInsights';
import { Colors } from '../../consts/colors';
import { BrancherCard } from '../../Components/General/BrancherCard';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { AcceptButton, RejectButton } from '../../Components/InputFields/BrancherButton';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { PartnerPersonalityMarker, PersonalityMarker } from './PersonalityMarkers';
import { IStoreTypes } from '../../store/storeTypes';
import {
  BrancherDispatch,
  SaveUserInfo,
  UtilGetPairInsights,
  UtilRequestUnlockInsights,
} from '../../store/actions';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { EValues, ValueBrancherNames } from '../../Form/Values/ValuesFormConfig';
import { GetRoute } from '../../Components/Routing';
import { EProfileAttributes } from '../../store/reducers/ProfileFormReducer';
import { BrancherList } from '../../Components/General/BrancherList';
import { GetIllustrationLink } from '../../utils/LinkUtils';
import { InsightsFeedback } from './InsightsFeedback';

export const ValuesMeanings: Array<{ readableName: string; description: string }> = [
  {
    readableName: ValueBrancherNames[EValues.SD],
    description: 'Exploring new ideas and making creative choices',
  },
  {
    readableName: ValueBrancherNames[EValues.STI],
    description: 'Seeking exciting experiences and novel challenges',
  },
  {
    readableName: ValueBrancherNames[EValues.HED],
    description: 'Seeking pleasure and enjoyment in everyday activities',
  },
  {
    readableName: ValueBrancherNames[EValues.ACH],
    description: 'Pursuing excellence, success, and recognition',
  },
  {
    readableName: ValueBrancherNames[EValues.POW],
    description: 'Gaining influence over resources and people',
  },
  {
    readableName: ValueBrancherNames[EValues.SEC],
    description: 'Maintaining safety, stability, and a sense of security',
  },
  {
    readableName: ValueBrancherNames[EValues.TRA],
    description: 'Upholding cultural customs and long-standing traditions',
  },
  {
    readableName: ValueBrancherNames[EValues.CON],
    description: 'Respecting established rules and social courtesy',
  },
  {
    readableName: ValueBrancherNames[EValues.BEN],
    description: 'Caring for others and actively supporting their well-being',
  },
  {
    readableName: ValueBrancherNames[EValues.UNI],
    description: 'Understanding, tolerance, and protecting the vulnerable',
  },
];

export const PersonalityMeanings: Array<{ readableName: string; description: string }> = [
  {
    readableName: 'Competitive vs. Principled',
    description: 'Tendency toward personal gain and advantage vs. ethics and rule-following.',
  },
  {
    readableName: 'Composed vs. Emotional',
    description: 'Tendency toward independence vs. emotional sensitivity and expression.',
  },
  {
    readableName: 'Introverted to Extroverted',
    description: 'Tendency toward solitude and reflection vs. sociability and external engagement.',
  },
  {
    readableName: 'Protective to Agreeable',
    description: 'Tendency toward comfort with conflict vs. conflict avoidance.',
  },
  {
    readableName: 'Laidback to Conscientious',
    description: 'Tendency toward spontaneity and ease vs. structure and goal focus.',
  },
  {
    readableName: 'Conservative to Innovative',
    description: 'Tendency toward conventionality vs. creativity and exploration.',
  },
];

const useStyles = makeStyles({
  flatCard: {
    borderRadius: 15,
    background: Colors.white,
    marginBottom: 15,
  },
  insightsMargin: {
    marginTop: 40,
  },
  insightsImage: {
    width: '100%',
    height: 100,
  },
  userLegend: {
    marginTop: -25,
    marginBottom: 20,
  },
});

interface IMatchInsights {
  match?: boolean;
}

export const MatchInsights: React.FC<IMatchInsights> = ({ match }) => {
  const pairInsights = useSelector((state: IStoreTypes) => state.user?.sessionPairInsights);
  const sessionPosition = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const sessionRoleId = useSelector((state: IStoreTypes) => state.user?.sessionRoleId);
  const programId = useSelector((state: IStoreTypes) => state.user?.programId);
  const [openTileDescription, setOpenTileDescription] = React.useState<boolean>(false);
  const insightsUnlockRequest = useSelector(
    (state: IStoreTypes) => state.user?.insightsUnlockRequest,
  );
  const partnerProfile = useSelector((state: IStoreTypes) => state.profileForm.pairProfile);
  const profile = useSelector((state: IStoreTypes) => state.profileForm.profile);
  const sessionPair = useSelector((state: IStoreTypes) => state.user.sessionPair?.roleId);
  const isMentee = sessionPosition === ProgramPositions.mentee;
  const [tileLegend, setTileLegend] = React.useState<'values' | 'personality'>();
  const [gettingInsights, setGettingInsights] = React.useState<boolean>(false);
  const [openRequestUnlock, setOpenRequestUnlock] = React.useState<boolean>(false);
  const [requestingUnlock, setRequestingUnlock] = React.useState<boolean>(false);
  const paid = useSelector((state: IStoreTypes) => state.user?.paidInsights);
  const styles = useStyles();
  const dispatch = useDispatch();
  const partnerName = useSelector((state: IStoreTypes) => state.user?.sessionPair?.name);

  const setSendOpenRequestUnlock = () => {
    setRequestingUnlock(true);
    BrancherDispatch(
      dispatch,
      UtilRequestUnlockInsights(() => {
        setOpenRequestUnlock(false);
        setRequestingUnlock(false);
      }),
    );
  };

  const partnerGivesInsightsPermissions = !!partnerProfile?.[
    EProfileAttributes.ALLOW_PARTNER_VIEW_INSIGHTS
  ];
  const userGivesInsightsPermissions = !!profile?.[EProfileAttributes.ALLOW_PARTNER_VIEW_INSIGHTS];
  const bothUsersAgreeToSeeInsights =
    partnerGivesInsightsPermissions && userGivesInsightsPermissions;
  const UsersToGiveInsightsPermissionText =
    !userGivesInsightsPermissions && !partnerGivesInsightsPermissions
      ? `you and ${partnerName.split(' ')[0]} haven't`
      : !userGivesInsightsPermissions
      ? "you haven't"
      : !partnerGivesInsightsPermissions
      ? `${partnerName.split(' ')[0]} hasn't`
      : '';
  const ShorterUsersToGiveInsightsPermissionText =
    !userGivesInsightsPermissions && !partnerGivesInsightsPermissions
      ? `You and ${partnerName.split(' ')[0]}`
      : !userGivesInsightsPermissions
      ? 'You '
      : !partnerGivesInsightsPermissions
      ? `${partnerName.split(' ')[0]}`
      : '';

  React.useEffect(() => {
    if (bothUsersAgreeToSeeInsights) {
      setGettingInsights(true);
      BrancherDispatch(
        dispatch,
        UtilGetPairInsights(() => {
          setGettingInsights(false);
        }),
      );
    } else {
      dispatch(
        SaveUserInfo({
          sessionPairInsights: {} as any,
        }),
      );
    }
  }, [sessionPair]);

  const ConversationStartersOne: string[] = [
    'Tell me about yourself.',
    'How do you spend your time outside of work?',
    "What's your proudest ever achievement?",
  ];

  const ConversationStartersTwo: string[] = [
    'What are your top three values?',
    'What do you care so much about your top values?',
    'What irritates you or triggers you?',
    'What demotivates you?',
    'What are the best and worst ways to communicate with you?',
  ];

  const ConversationStartersThree: string[] = [
    'Which of the strengths from Brancher Insights stands out most?',
    'What do others not know about you?',
    'What are three honest unfiltered things about you?',
    'What are you currently working on to drive personal growth?',
    'Which `watch outs` from the Brancher Insights resonated most and why?',
    'How do you like to give and receive feedback?',
  ];

  return (
    <PageLayout pageTitle={`Your Match Insights with ${partnerName}`}>
      <BrancherDialog
        setClose={() => setOpenRequestUnlock(false)}
        open={openRequestUnlock}
        labelledBy="unlock match insights"
        contentWidth
        title="Would you like to 'Request to Unlock' this insight?"
      >
        <Grid container justify="center">
          <Grid container item>
            <Text variant="md" marginBottom={50} marginTop={20}>
              This will notify your program administrator that you would like to see this insight.
            </Text>
          </Grid>
          <Grid item xs={12} md={8} container justify="space-between">
            <RejectButton disabled={requestingUnlock} onClick={() => setOpenRequestUnlock(false)}>
              No
            </RejectButton>
            <AcceptButton disabled={requestingUnlock} onClick={setSendOpenRequestUnlock}>
              Yes
            </AcceptButton>
          </Grid>
        </Grid>
      </BrancherDialog>
      <BrancherDialog
        labelledBy={tileLegend === 'values' ? 'values-legend' : 'personality-legend'}
        open={openTileDescription}
        setClose={() => setOpenTileDescription(false)}
        contentWidth
        title={tileLegend === 'values' ? 'Values Legend' : 'Personality Legend'}
      >
        {(tileLegend === 'values' ? ValuesMeanings : PersonalityMeanings).map((val) => (
          <Box display="block" width="100%">
            <Text variant="md" fontWeight={600} color="purple" display="inline">
              {val.readableName}:{' '}
            </Text>
            <Text variant="md" fontWeight={500} display="inline">
              {val.description}
            </Text>
          </Box>
        ))}
      </BrancherDialog>
      <Grid container justify="center">
        <Grid container item xs={12} spacing={2}>
          <Grid item>
            <Text variant="md" color="purple" fontWeight={600} marginTop={-20} marginBottom={15}>
              Understand Your Match for a Stronger Connection
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text variant="sm" display="inline">
              Your{' '}
            </Text>
            <Text variant="sm" display="inline" fontWeight={600}>
              Match-Insights{' '}
            </Text>
            <Text variant="sm" display="inline">
              provide a deeper look into how you and your mentor/mentee align—highlighting shared
              strengths, differences, and how to best work together. You’ll see key similarities and
              differences in personality, values, and communication styles. Use these insights to
              build rapport, navigate challenges, and create a productive mentoring relationship. We
              encourage you to discuss your insights together in your next meeting.
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text variant="sm">
              If something doesn’t feel quite right, consider it a reflection point—differences in
              perception can reveal blind spots or areas for growth. Think about how your match
              might experience these traits in you, and use this as an opportunity for open
              conversation and deeper self-awareness.
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text variant="sm" display="inline" fontWeight={600}>
              🔒 Note:{' '}
            </Text>
            <Text variant="sm" display="inline">
              Your Match Insights are locked at the time of matching. Even if you update your{' '}
            </Text>
            <BrancherLinkText
              fontWeight={500}
              underline="always"
              variant="sm"
              display="inline"
              href={GetRoute('insights').path}
            >
              Self-Insights
            </BrancherLinkText>
            <Text variant="sm" display="inline">
              , this page will remain the same.
            </Text>
          </Grid>
          {bothUsersAgreeToSeeInsights ? (
            <Grid item xs={12}>
              <Text variant="sm" display="inline">
                You’re seeing these insights because both {`you and ${partnerName.split(' ')[0]}`}{' '}
                have given permission. You can update this anytime in your{' '}
              </Text>
              <BrancherLinkText
                variant="sm"
                display="inline"
                underline="always"
                href={GetRoute('settings').path}
              >
                profile
              </BrancherLinkText>
              <Text variant="sm" display="inline">
                {' '}
                settings.
              </Text>
            </Grid>
          ) : (
            <Grid item xs={12} className={styles.insightsMargin}>
              <Text variant="sm" display="inline" fontWeight={500}>
                You are unable to see your match insights because{' '}
                {UsersToGiveInsightsPermissionText} given permission.{' '}
                {ShorterUsersToGiveInsightsPermissionText} can update this anytime in the{' '}
              </Text>
              <BrancherLinkText
                variant="sm"
                display="inline"
                fontWeight={500}
                underline="always"
                href={GetRoute('settings').path}
              >
                profile
              </BrancherLinkText>
              <Text variant="sm" display="inline" fontWeight={500}>
                {' '}
                settings.
              </Text>
            </Grid>
          )}
        </Grid>
        {bothUsersAgreeToSeeInsights &&
          (!gettingInsights && !!pairInsights?.personality ? (
            <Grid container justify="space-between" spacing={2} className={styles.insightsMargin}>
              <Grid item container xs={12} lg={6}>
                <BrancherCard>
                  <Grid item xs={12} container justify="space-between">
                    <Grid item container justify="space-between">
                      <Grid item>
                        <Text variant="lg" fontWeight={700} marginBottom={4}>
                          {paid ? 'Our Personality' : 'Top 3 Personality matches'}
                        </Text>
                        <Text variant="xxs" fontWeight={600} color="placeholderGrey">
                          Our unique approach
                        </Text>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            setTileLegend('personality');
                            setOpenTileDescription(true);
                          }}
                        >
                          <Info fontSize="large" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} alignItems="flex-start" className={styles.flatCard}>
                    {paid ? (
                      <>
                        <Grid item container justify="center" className={styles.userLegend}>
                          <Box display="inline-flex" alignItems="center">
                            <PersonalityMarker />
                            <Text variant="xxs" marginLeft={-4} fontWeight={600} marginTop={4}>
                              You
                            </Text>
                          </Box>
                          <Box display="inline-flex" alignItems="center" marginLeft={2}>
                            <PartnerPersonalityMarker />
                            <Text variant="xxs" marginLeft={-4} fontWeight={600} marginTop={4}>
                              {partnerName.split(' ')[0]}
                            </Text>
                          </Box>
                        </Grid>
                        <PersonalityScaler
                          personalityScales={sortObjectsByEnum(
                            pairInsights.personality,
                            'personality',
                          )}
                          isMatch
                        />
                      </>
                    ) : (
                      <>
                        <SimpleMatchInsights
                          insights={pairInsights.personality}
                          insightType={EMatchInsightType.PERSONALITY}
                        />
                        <Grid item xs={12}>
                          <Grid item container justify="space-between" xs={12}>
                            {!insightsUnlockRequest?.[programId]?.[sessionRoleId] ? (
                              <Grid item alignItems="center" container>
                                <Text variant="xxs" display="inline">
                                  Want to know more?{' '}
                                </Text>
                                <BrancherLinkText
                                  onClick={() => setOpenRequestUnlock(true)}
                                  underline="always"
                                  display="inline"
                                  variant="xxs"
                                  fontWeight={600}
                                  color="purple"
                                >
                                  Request to Unlock
                                </BrancherLinkText>
                              </Grid>
                            ) : (
                              <Grid item>
                                <Text variant="xxs" fontWeight={600} color="purple">
                                  Extended Insights requested
                                </Text>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </BrancherCard>
              </Grid>
              <Grid item container xs={12} lg={6} spacing={2}>
                <Grid container item xs={12}>
                  <InsightsCard {...pairInsights?.insights?.[EInsights.SHARED_STRENGTHS]} />
                </Grid>
                <Grid container item xs={12}>
                  <InsightsCard {...pairInsights?.insights?.[EInsights.VARYING_STYLES]} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <BrancherDivider marginTop={50} marginBottom={50} />
              </Grid>
              <Grid container justify="center">
                <Grid container justify="space-between" spacing={2}>
                  <Grid item container xs={12} lg={6}>
                    <BrancherCard>
                      <Grid item xs={12} container justify="space-between">
                        <Grid item>
                          <Text variant="lg" fontWeight={700} marginBottom={4}>
                            {paid ? 'Our Values' : 'Top 3 Value matches'}
                          </Text>
                          <Text variant="xxs" fontWeight={600} color="placeholderGrey">
                            What matters most to us
                          </Text>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={() => {
                              setTileLegend('values');
                              setOpenTileDescription(true);
                            }}
                          >
                            <Info fontSize="large" />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container alignItems="flex-start">
                        {paid ? (
                          <BrancherRadarChart
                            categories={sortObjectsByEnum(pairInsights.values, 'values').map(
                              (b) => b.attributeName,
                            )}
                            yourCategoryValues={sortObjectsByEnum(
                              pairInsights.values,
                              'values',
                            ).map((b) => (isMentee ? b.menteeValue : b.mentorValue))}
                            partnerCategoryValues={sortObjectsByEnum(
                              pairInsights.values,
                              'values',
                            ).map((b) => (isMentee ? b.mentorValue : b.menteeValue))}
                          />
                        ) : (
                          <>
                            <SimpleMatchInsights
                              insights={pairInsights.values}
                              insightType={EMatchInsightType.VALUES}
                            />
                            <Grid item xs={12}>
                              <Grid item container justify="space-between" xs={12}>
                                {!insightsUnlockRequest?.[programId]?.[sessionRoleId] ? (
                                  <Grid item alignItems="center" container>
                                    <Text variant="xxs" display="inline">
                                      Want to know more?{' '}
                                    </Text>
                                    <BrancherLinkText
                                      onClick={() => setOpenRequestUnlock(true)}
                                      underline="always"
                                      display="inline"
                                      variant="xxs"
                                      fontWeight={600}
                                      color="purple"
                                    >
                                      Request to Unlock
                                    </BrancherLinkText>
                                  </Grid>
                                ) : (
                                  <Grid item>
                                    <Text variant="xxs" fontWeight={600} color="purple">
                                      Extended Insights requested
                                    </Text>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </BrancherCard>
                  </Grid>
                  <Grid container item xs={12} lg={6} spacing={2}>
                    <Grid container item xs={12}>
                      <InsightsCard {...pairInsights?.insights?.[EInsights.SHARED_MOTIVATORS]} />
                    </Grid>
                    <Grid container item xs={12}>
                      <InsightsCard {...pairInsights?.insights?.[EInsights.VARYING_VALUES]} />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <InsightsFeedback />
                  </Grid>
                  <Grid container item xs={12} lg={6}>
                    <BrancherCard padding="40px 0px 0px 0px">
                      <Box ml={5} mr={5} mb={3} width="100%">
                        <Grid item xs={12}>
                          <Text variant="xl" fontWeight={700} marginBottom={15}>
                            Conversation starters with your match
                          </Text>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                          <BrancherList
                            listItems={ConversationStartersOne}
                            fontWeight={400}
                            variant="xs"
                          />
                          <Grid item xs={12}>
                            <BrancherDivider />
                          </Grid>
                          <BrancherList
                            listItems={ConversationStartersTwo}
                            fontWeight={400}
                            variant="xs"
                          />
                          <Grid item xs={12}>
                            <BrancherDivider />
                          </Grid>
                          <BrancherList
                            listItems={ConversationStartersThree}
                            fontWeight={400}
                            variant="xs"
                          />
                        </Grid>
                      </Box>
                      <Grid container item xs={12} alignItems="flex-end">
                        <img
                          src={GetIllustrationLink('conversationStarters.png')}
                          alt="conversation-starters"
                          className={styles.insightsImage}
                        />
                      </Grid>
                    </BrancherCard>
                  </Grid>
                  <Grid container item xs={12} lg={6}>
                    <BrancherCard padding="40px 0px 0px 0px">
                      <Box ml={5} mr={5} mb={3} width="100%">
                        <Grid item xs={12}>
                          <Text variant="xl" fontWeight={700} marginBottom={35}>
                            Tips and hints for interacting with your match
                          </Text>
                        </Grid>
                        <Grid container item xs={12} spacing={5}>
                          <Grid item>
                            <Text variant="sm" display="inline" fontWeight={600}>
                              ✅ Leverage Strengths –{' '}
                            </Text>
                            <Text variant="sm" display="inline">
                              Have a discussion about what both of your strengths are
                            </Text>
                          </Grid>
                          <Grid item>
                            <Text variant="sm" display="inline" fontWeight={600}>
                              🗣️ Adapt Communication –{' '}
                            </Text>
                            <Text variant="sm" display="inline">
                              Ask each other about how you like to communicate
                            </Text>
                          </Grid>
                          <Grid item>
                            <Text variant="sm" display="inline" fontWeight={600}>
                              ⚖️ Balance Differences –{' '}
                            </Text>
                            <Text variant="sm" display="inline">
                              Explore how differences can be an advantage
                            </Text>
                          </Grid>
                          <Grid item>
                            <Text variant="sm" display="inline" fontWeight={600}>
                              🎯 Set Expectations –{' '}
                            </Text>
                            <Text variant="sm" display="inline">
                              Set goals together and agree on how to give feedback
                            </Text>
                          </Grid>
                          <Grid item>
                            <Text variant="sm" display="inline" fontWeight={600}>
                              🚦 Navigate Challenges –{' '}
                            </Text>
                            <Text variant="sm" display="inline">
                              Identify potential roadblocks and decide how to handle them
                            </Text>
                          </Grid>
                          <Grid item>
                            <Text variant="sm" display="inline" fontWeight={600}>
                              🤝 Create Safety –{' '}
                            </Text>
                            <Text variant="sm" display="inline">
                              Encourage questions and show appreciation for honesty
                            </Text>
                          </Grid>
                        </Grid>
                      </Box>
                      <Grid container item xs={12} alignItems="flex-end">
                        <img
                          src={GetIllustrationLink('tipsHints.png')}
                          alt="tips-and-hints"
                          className={styles.insightsImage}
                        />
                      </Grid>
                    </BrancherCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <CircularProgress color="secondary" size={64} />
          ))}
      </Grid>
    </PageLayout>
  );
};

export const PersonalityEnumOrder = {
  HUMILITY: 'humility',
  EMOTIONALITY: 'emotionality',
  EXTRAVERSION: 'extraversion',
  AGREEABLENESS: 'agreeableness',
  CONSCIENTIOUSNESS: 'conscientiousness',
  OPENNESS: 'openness',
};

const ValuesEnumOrder = {
  SD: 'SD',
  STI: 'STI',
  HED: 'HED',
  ACH: 'ACH',
  POW: 'POW',
  SEC: 'SEC',
  TRA: 'TRA',
  CON: 'CON',
  BEN: 'BEN',
  UNI: 'UNI',
};

export const sortObjectsByEnum = (insights: any[], type: 'values' | 'personality') => {
  const attributeName = 'attributeName';
  const order = Object.values(type === 'values' ? ValuesEnumOrder : PersonalityEnumOrder);

  return insights.sort((a, b) => {
    return order.indexOf(a[attributeName]) - order.indexOf(b[attributeName]);
  });
};
